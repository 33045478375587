<template>
  <div class="dashboard">
    <div class="row gy-3">
      <div class="col-md-12">
        <h6 class="">Hi, {{activeUser.first_name}}</h6>
        <div v-if="result.department.title">
          <h5 class="text-primary">Welcome to the deparment of {{result.department.title || '...'}}</h5>
          <p>{{ result.department.description }}</p>
        </div>
      </div>
      <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
          <user-email-verification />                   
      </div>
    </div>

    <div class="row pt-5">
      <div class="col-12">
        <case-list />
      </div>
    </div>
  </div>
</template>

<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"
import CaseList from './cases/CaseList.vue';

export default {
    name: 'dashboard',
    components:{
      CaseList,
      UserEmailVerification
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
    created(){
      this.$http.get('/analytics')
      .then((response) => {
        if(response.data.success){
          this.$store.commit('UPDATE_DASHBOARD', response.data.data)
        }
      })
    }
}
</script>

