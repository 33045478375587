
<template>
<form autocomplete="off" class="">
    <div class="row g-lg-4 g-3">
        <div class="col-sm-6">
            <label class="form-label">Created between</label>
            <flat-pickr
                v-model="data.created_between"
                :config="rangeConfig"
                placeholder="Select date"
                class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
        <div class="col-sm-6">
            <label>Category</label>
            <select class="form-select" v-model="data.case_category_id"
                name="input-category" placeholder="--select category--">
                <option disabled value="" key="-1">-- select category--</option>
                <option :value="category.id" v-for="(category, catIndex) in categoryList" 
                :key="catIndex">{{ category.title }}</option>
            </select>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
    Multiselect,
    flatPickr
    },
    data() {
    return {
        data: {
            created_between: "",
            case_category_id: "",
        },
        dateConfig:{
            wrap: true, 
            altFormat: 'M j, Y',
            altInput: true,
            dateFormat: 'Y-m-d',
            // enableTime: true,
        },
        rangeConfig: {
            mode: "range",
            dateFormat: "Y-m-d",
            altInput: true,
            showMonths: 2,
            altFormat: "F j, Y",
            // minDate: "today",
            maxDate: new Date().fp_incr(1)
        },
        mirrorData: null
    }
    },
    computed:{
        categoryList(){
            return this.$store.state.caseCategory.list
        },
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    created(){
        this.$store.dispatch('caseCategory/fetchAllCategories')
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
